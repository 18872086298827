<template>
    <b-row class="justify-content-md-center" :style="rowStyle">
      <b-col lg=12 align="center">
        <master-title>Faire un don</master-title>
        <p class="large-text">Faire un don à l'association Tim et Bastien, c'est nous permettre de développer le projet pour toucher encore plus d'étudiant·es.</p>
        <p class="large-text">Depuis le début de l'aventure, Tim&Bastien c'est plus de 10 000 repas servis aux étudiants (une moyenne de 50 repas/jour).</p>
        <p class="large-text">A terme, nous voulons que tou.te.s les étudiant.e.s aient accès à une alimentation saine.</p>
        <p class="large-text">L'association Tim & Bastien est reconnue d'intérêt général. A ce titre, elle peut émettre des reçus fiscaux permettant aux donateurs.rices de bénéficier d'une réduction d'impôt de 66% de la valeur du don.</p>
        <!-- <b-button variant="outline-secondary" size="lg" href="https://www.helloasso.com/associations/tim-et-bastien/formulaires/1" target="_blank">Faire un don</b-button> -->
        <div class="tb-btns">
          <b-button href="https://www.helloasso.com/associations/tim-et-bastien/formulaires/1" target="_blank">
            <font-awesome-icon
              style="color: #e1c726;"
              size="2x"
              icon="fa-solid fa-coins"
            />
            Faites un don au projet
          </b-button>
          <b-button href="https://docs.google.com/forms/d/e/1FAIpQLScElRhPWQeZGQDarh3NiaG09pipfZY9ry_EYtJ-hNGi6lUe-Q/viewform?usp=dialog" target="_blank">
            <font-awesome-icon
              style="color: #FC9B37;"
              size="2x"
              icon="fa-solid fa-users"
            />
            Adhérez à l'association
          </b-button>
          <b-button href="/login">
            <font-awesome-icon
              style="color: rgb(148 104 209);"
              size="2x"
              icon="fa-solid fa-envelope"
            />
            Abonnez-vous à notre newsletter
          </b-button>
        </div>
      </b-col>
    </b-row>
</template>

<script>
import MasterTitle from '@/components/utils/MasterTitle.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoins, faUsers, faEnvelope } from '@fortawesome/free-solid-svg-icons'

library.add(faCoins)
library.add(faUsers)
library.add(faEnvelope)

export default {
  name: 'FaireUnDon',
  components: { MasterTitle },
  props: {
    fullHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rowStyle() {
      var style = {}
      if (this.fullHeight) {
        style.minHeight = "85vh";
      }
      return style;
    },
  },
}
</script>

<style>
</style>
